import { getDatapoints, iterateDatapoints } from 'components/Annotation/utils';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import create from 'zustand';
import {
  createAnnotationItem,
  deleteAnnotationItem,
  patchAnnotationItem,
  putAnnotationStatus,
} from '../../services/annotations';
import {
  invalidateAllDocuments,
  invalidateAnnotation,
  invalidateAnnotationContent,
  invalidateAnnotations,
  useAnnotations,
  useDocument,
} from '../../services/documents';
import { useTranslate } from '../Internationalisation/useTranslate';
import { VerticallySplitContainer } from '../Layout/VerticallySplitContainer';
import { AnnotationPanel } from './AnnotationPanel/AnnotationPanel';
import {
  currentReviewAnnotation,
  documentIsProcessing,
  isTestModeEnabledAtom,
  reviewDocumentsNumberAtom,
} from './AnnotationState';
import { ChangePageModal } from './ChangePageModal';
import { DocumentPanel } from './DocumentPanel/DocumentPanel';
import { annotationToGeometryBox } from './utils';

// TODO: Refactor using Jotai and custom hooks.
export const useAnnotationStore = create((set, get) => ({
  // TODO: Rename to isSelectedAnnotationProcessed
  isDocumentProcessed: undefined,
  documentType: undefined,
  annotation: null,
  annotationContent: null,
  schema: null,
  // To focus new page we change focusedPageIndex and document viewer then updates
  // currentPageIndex to the same value after focusing the new page.
  currentPageIndex: 0,
  focusedPageIndex: 0,
  allDatapoints: [],

  getImageAnnotations: (allowedAnnotationClasses) => {
    const annotation = get().annotation;
    const annotationContent = get().annotationContent;
    const datapointAnnotations = Array.from(
      new Array(annotation.pages.length),
      () => []
    );

    if (!annotationContent) {
      return datapointAnnotations;
    }
    const datapoints = getDatapoints(annotationContent, allowedAnnotationClasses);
    Object.values(datapoints).forEach((datapoint) => {
      datapoint.box &&
        datapointAnnotations[datapoint.page_index]?.push({
          data: {
            text: datapoint.value,
            normalizedValue: datapoint.normalized_value,
            id: datapoint.id,
            schema_element_id: datapoint.schema_element_id,
            confidence: datapoint.confidence,
            annotation_class: datapoint.annotation_class,
          },
          geometry: {
            type: 'RECTANGLE',
            ...annotationToGeometryBox(datapoint.box),
          },
        });
    });
    return datapointAnnotations;
  },

  setCurrentPageIndex: (pageIndex) =>
    set({ focusedPageIndex: pageIndex, currentPageIndex: pageIndex }),
  focusPage: (pageIndex) => {
    set({ focusedPageIndex: pageIndex });
  },
  setIsDocumentProcessed: (isDocumentProcessed) => set({ isDocumentProcessed }),
  setDocumentType: (documentType) => set({ documentType }),
  setSchema: (schema) => set({ schema: schema }),
  setAllDatapoints: (datapoints) => set({ allDatapoints: datapoints }),
  setAnnotationContent: (annotationContent) =>
    set({ annotationContent: annotationContent }),
  setAnnotation: (annotation) => set({ annotation: annotation }),
  createAnnotationItem: (item) => {
    const annotation = get().annotation;
    return createAnnotationItem(annotation.id, item)
      .then(() => {
        return invalidateAnnotationContent(annotation.id);
      })
      .catch(console.log);
  },
  deleteAnnotationItem: (itemId) => {
    const annotation = get().annotation;
    return deleteAnnotationItem(annotation.id, itemId)
      .then(() => {
        return invalidateAnnotationContent(annotation.id);
      })
      .catch(console.log);
  },
  patchAnnotation: (annotationItemId, patch) => {
    const annotation = get().annotation;
    get().updateDatapointValue(annotationItemId, patch);
    return patchAnnotationItem(annotation.id, annotationItemId, patch)
      .then(() => {
        return invalidateAnnotationContent(annotation.id);
      })
      .catch(console.log);
  },

  patchAnnotationWithoutContentUpdate: (annotationItemId, patch) => {
    const annotation = get().annotation;
    get().updateDatapointValue(annotationItemId, patch);
    return patchAnnotationItem(annotation.id, annotationItemId, patch).catch(
      console.log
    );
  },
  // Not used as we load annotation content from API on each change now.
  updateDatapointValue: (datapointId, patch) => {
    // Temporary update of annotation field before the whole updated annotation is fetched.
    // It helps to keep resized bounding box after editing without changing it to old one
    // until the new annotation is fetched. Datapoint values on the left don't reflect
    // this update as the data is managed by React Query.
    // We should switch from this zustand store to hooks with atoms and utilize more
    // React Query mutations.
    const content = JSON.parse(JSON.stringify(get().annotationContent));
    for (const datapoint of iterateDatapoints(content)) {
      if (datapoint.id === datapointId) {
        datapoint.value = patch.value;
        datapoint.normalized_value = patch.normalized_value;
        datapoint.box = {
          x_min: patch.box_x_min,
          x_max: patch.box_x_max,
          y_min: patch.box_y_min,
          y_max: patch.box_y_max,
        };
      }
    }
    set({ annotationContent: content });
  },
}));

export const AnnotationPage = () => {
  const { documentId } = useParams();

  const { data: document } = useDocument(documentId, {
    enabled: !!documentId,
    retry: (failureCount, error) => {
      return failureCount < 3 && error?.response?.status != 404;
    },
  });

  const [currentAnnotation, setCurrentReviewAnnotation] = useAtom(
    currentReviewAnnotation
  );

  const [, setReviewAnotationQueryString] = useAtom(reviewDocumentsNumberAtom);
  const [, setReviewAnnotationNumber] = useAtom(reviewDocumentsNumberAtom);
  const [, setIsTestModeEnabled] = useAtom(isTestModeEnabledAtom);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const focusPage = useAnnotationStore((state) => state.focusPage);
  const intl = useTranslate();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const annotationIdUrl = new URLSearchParams(search).get('annotationId');
  const [documentsInProcessing, setDocumentIsProcessing] = useAtom(
    documentIsProcessing
  );
  const annotationIdGroundTruth = new URLSearchParams(search).get(
    'annotationIdGroundTruth'
  );
  const showTestAnnotations = !!annotationIdGroundTruth;
  const { data } = useAnnotations(documentId, showTestAnnotations);
  const annotations = data?.data;
  const setDocumentType = useAnnotationStore((state) => state.setDocumentType);

  useEffect(() => {
    if (document) {
      switch (document.media_type) {
        // case 'application/pdf':
        case 'pdf':
          setDocumentType('pdf');
          break;
        case 'doc':
        case 'docx':
          setDocumentType('pdf');
          break;
        case 'xlsx':
        case 'xls':
        case 'xlsm':
        case 'ods':
        case 'csv':
          // case 'application/vnd.ms-excel':
          // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          // case 'text/csv':
          // case 'application/vnd.oasis.opendocument.spreadsheet':
          // case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          setDocumentType('spreadsheet');
          break;
        case 'jpeg':
        case 'png':
          // case 'image/jpeg':
          // case 'image/png':
          setDocumentType('image');
          break;
        default:
          break;
      }
    }
  }, [document]);

  useEffect(() => {
    setIsTestModeEnabled(!!showTestAnnotations);
  }, [showTestAnnotations]);

  useEffect(() => {
    if (
      documentsInProcessing &&
      documentsInProcessing[documentId] === true &&
      annotations.length
    ) {
      const newDocumentProcessing = { ...documentIsProcessing };
      delete newDocumentProcessing[documentId];
      setDocumentIsProcessing(newDocumentProcessing);
      onAnnotationSelectionChange(annotations[0].id);
    }
  }, [documentsInProcessing]);

  useEffect(() => {
    if (currentAnnotation) {
      setShouldBlockNavigation(selectedAnnotationId === currentAnnotation.annotationId);
    } else {
      setShouldBlockNavigation(false);
    }
  }, [selectedAnnotationId, currentAnnotation]);

  const onAnnotationSelectionChange = (annotationId) => {
    setSelectedAnnotationId(annotationId);
    invalidateAnnotationContent(annotationId);
    if (!!annotationId && annotationId !== annotationIdUrl) {
      let search = `?annotationId=${annotationId}`;

      if (annotationIdGroundTruth) {
        search = `${search}&annotationIdGroundTruth=${annotationIdGroundTruth}`;
      }
      navigate(
        {
          pathname,
          search,
        },
        { replace: true }
      );
    }
  };

  const annotationPanel = (
    <AnnotationPanel
      documentId={documentId}
      selectedAnnotationId={selectedAnnotationId}
      onAnnotationSelectionChange={onAnnotationSelectionChange}
    />
  );
  const documentPanel = <DocumentPanel documentId={documentId} />;

  const updateAnnotationStatus = (annotationId, documentId) => {
    putAnnotationStatus(annotationId, 'to_review')
      .then(() => invalidateAnnotations(documentId))
      .then(() => invalidateAnnotation(annotationId));
  };

  const onConfirmChangeLocation = () => {
    setCurrentReviewAnnotation(undefined);
    setReviewAnotationQueryString(undefined);
    setReviewAnnotationNumber(undefined);
    updateAnnotationStatus(selectedAnnotationId, documentId);
    return true;
  };

  useEffect(() => {
    invalidateAllDocuments();
    focusPage(0);
  }, []);

  return (
    <>
      <VerticallySplitContainer
        leftPanel={annotationPanel}
        rightPanel={documentPanel}
        leftPanelSize={35}
        paddingSize="none"
      />
      <ChangePageModal
        when={shouldBlockNavigation}
        title={intl.formatMessage({
          id: 'annotation.changeModal.title',
          defaultMessage: 'Leave this page',
        })}
        cancelText={intl.formatMessage({
          id: 'button.cancel',
          defaultMessage: 'Cancel',
        })}
        confirmText={intl.formatMessage({
          id: 'button.confirm',
          defaultMessage: 'Confirm',
        })}
        onConfirm={onConfirmChangeLocation}
        message={intl.formatMessage({
          id: 'annotation.changeModal.message',
          defaultMessage: 'Are you sure you want to leave review mode?',
        })}
      />
    </>
  );
};
