import { EuiIcon } from '@elastic/eui';
import { useTranslate } from '../Internationalisation/useTranslate';

export function useRedactionStatusList() {
  const intl = useTranslate();

  const redactionStatusList: Record<string, { color: string; label: string }> = {
    to_redact: {
      color: 'primary',
      label: intl.formatMessage({
        id: 'redactionStatus.to_redact',
        defaultMessage: 'To redact',
      }),
    },
    redaction_failed: {
      color: 'danger',
      label: intl.formatMessage({
        id: 'redactionStatus.redactionFailed',
        defaultMessage: 'Redaction failed',
      }),
    },
    redacting: {
      color: 'primary',
      label: intl.formatMessage({
        id: 'redactionStatus.redacting',
        defaultMessage: 'Redacting',
      }),
    },
    redacted: {
      color: 'success',
      label: intl.formatMessage({
        id: 'redactionStatus.redacted',
        defaultMessage: 'Redacted',
      }),
    },
  };

  return redactionStatusList
}

export function RedactionStatusIcon( {status} ) {
  const statusList = useRedactionStatusList()
  return (
    status && <EuiIcon type="eraser" color={statusList[status].color} title={statusList[status].label} aria-label={statusList[status].label}/>

  );
}