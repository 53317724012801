import {
  DropResult,
  EuiAccordion,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiComboBox,
  EuiConfirmModal,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFlyoutProps,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSelect,
  EuiSplitPanel,
  EuiSwitch,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { useAnnotationItems } from 'components/Annotation/AnnotationPanel/useAnnotationItems';
import { BOTTOM_BAR_HEIGHT } from 'components/BottomBar/BottomBar';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ulid } from 'ulid';
import { Can, PERMISSION } from 'utils/permissions';
import {
  EnumChoice,
  SchemaDatapoint,
  SchemaDatapointEnum,
  SchemaGetOut,
  SchemaList,
  SchemaSection,
  SchemaTable,
} from '../../../Definitions/ApiTypes';
import { DatapointType } from '../../../Definitions/DatapointType';
import { SchemaBuilderProps } from '../../../Definitions/SchemaBuilderProps';
import { SchemaContentType } from '../../../Definitions/SchemaContentType';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';
import { useSchema } from '../../../services/documents';
import { useSchemaUpdate } from '../../../utils/useSchemaUpdate';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { NestedDatapoint } from './NestedDatapoint';
import { SchemaContentEditor } from './SchemaContentEditor';
import { SchemaHooks } from './SchemaHooks';
import { SchemaSaveAsNewModalWindow } from './SchemaSaveAsNewModalWindow';
import { SchemaUpdate } from './SchemaUpdate';
import { useSchemas } from './useSchemas';

enum FlyoutMode {
  CreateDatapoint = 'CREATE_DATAPOINT',
  EditDatapoint = 'EDIT_DATAPOINT',
  CreateSection = 'CREATE_SECTION',
  EditSection = 'EDIT_SECTION',
  CreateList = 'CREATE_LIST',
  EditList = 'EDIT_LIST',
  CreateTable = 'CREATE_TABLE',
  EditTable = 'EDIT_TABLE',
  JsonEditor = 'JSON_EDITOR',
}

export function SchemaBuilder(props: SchemaBuilderProps) {
  // Use items so they are updated after schema update and user can annotate without
  // switching from schema to annotation items.
  useAnnotationItems(props?.annotationId, props.schemaId);
  const { data } = useSchema(props.schemaId);
  const { saveSchemaChanges, isSaving, updateFinishCode, errorCode } = useSchemaUpdate(
    props.schemaId
  );
  const [isOpenSaveAsNewModal, setIsOpenSaveAsNewModal] = useState<boolean>(false);
  const [schemaData, setSchemaData] = useState<{
    sections: SchemaSection[];
    isTouched: boolean;
  }>({
    sections: [],
    isTouched: false,
  });
  const [contentHooks, setContentHooks] = useState<string[] | null>([]);
  const [activeDataElementIndex, setActiveDataElementIndex] = useState<number>();
  const [activeSectionId, setActiveSectionId] = useState<string>('');
  const [activeDatapoint, setActiveDatapoint] = useState<
    SchemaDatapoint | SchemaDatapointEnum
  >();
  const [activeList, setActiveList] = useState<SchemaList>();
  const [activeTable, setActiveTable] = useState<SchemaTable>();
  const [activeTableStoredDatapointIds, setActiveTableStoredDatapointIds] = useState<
    string[]
  >([]);
  const [activeSection, setActiveSection] = useState<SchemaSection>();
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>();
  const [flyoutProps, setFlyoutProps] = useState<{
    isVisible: boolean;
    ownFocus: boolean;
    type: EuiFlyoutProps['type'];
    mode?: FlyoutMode;
  }>({
    isVisible: false,
    ownFocus: false,
    type: props.flyoutType,
  });
  const [dataElementToDelete, setDataElementToDelete] = useState<{
    id: string;
    label: string;
  }>();
  const [
    isDeletionConfirmModalVisible,
    setIsDeletionConfirmModalVisible,
  ] = useState<boolean>(false);
  const [
    isDeleteSectionConfirmModalVisible,
    setIsDeleteSectionConfirmModalVisible,
  ] = useState<boolean>(false);
  const [
    visibleEditSchemaElementIdModal,
    setVisibleEditSchemaElementIdModal,
  ] = useState<
    | 'section'
    | 'datapoint'
    | 'list'
    | 'list_datapoint'
    | 'table'
    | 'table_tuple'
    | 'table_datapoint'
    | null
  >(null);
  const [activeDatapointEnumChoices, setActiveDatapointEnumChoices] = useState<
    Array<EnumChoice & { localId: string }>
  >([]);
  const [isDragAndDropDisabled, setIsDragAndDropDisabled] = useState<boolean>(false);
  const jsonEditorRef = useRef();
  const intl = useTranslate();

  const isEditFlyout = [
    FlyoutMode.EditDatapoint,
    FlyoutMode.EditList,
    FlyoutMode.EditSection,
    FlyoutMode.EditTable,
  ].includes(flyoutProps.mode);

  let flyout;
  let deleteDataElementConfirmModal;
  let deleteSectionConfirmModal;

  const handleHooksChange = useCallback(
    (hooks: Array<string>) => {
      setActiveDatapoint((activeDatapoint) => {
        if (!activeDatapoint) {
          return activeDatapoint;
        }
        return {
          ...activeDatapoint,
          hooks: hooks,
        };
      });
    },
    [setActiveDatapoint]
  );

  const handleDatapointRedactionEnabledChange = useCallback(
    (e) => {
      setActiveDatapoint((activeDatapoint) => {
        if (!activeDatapoint) {
          return activeDatapoint;
        }
        return {
          ...activeDatapoint,
          redaction: {is_enabled: e.target.checked},
        };
      });
    },
    [setActiveDatapoint]
  );

  function onDatapointEnumValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    choiceId: string
  ) {
    const updatedEnumChoices = [...activeDatapointEnumChoices];
    const affectedChoiceIndex = activeDatapointEnumChoices.findIndex(
      (item) => item.localId === choiceId
    );
    if (affectedChoiceIndex > -1) {
      updatedEnumChoices[affectedChoiceIndex].value = e.target.value;
    }
    setActiveDatapointEnumChoices(updatedEnumChoices);
  }

  function onDatapointEnumLabelChange(
    e: React.ChangeEvent<HTMLInputElement>,
    choiceId: string
  ) {
    const updatedEnumChoices = [...activeDatapointEnumChoices];
    const affectedChoiceIndex = activeDatapointEnumChoices.findIndex(
      (item) => item.localId === choiceId
    );
    if (affectedChoiceIndex > -1) {
      updatedEnumChoices[affectedChoiceIndex].label = e.target.value;
    }
    setActiveDatapointEnumChoices(updatedEnumChoices);
  }

  function handleRemoveDatapointEnumChoice(choiceId: string) {
    setActiveDatapointEnumChoices(
      activeDatapointEnumChoices.filter((item) => item.localId !== choiceId)
    );
  }

  function addNewEnumChoice() {
    const newEmptyChoice = {
      localId: ulid(),
      value: '',
      label: '',
    };
    setActiveDatapointEnumChoices([...activeDatapointEnumChoices, newEmptyChoice]);
  }

  function onNestedDatapointIdChange(index: number, value: string) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      activeTableElements[index] = {
        ...activeTableElements[index],
        id: value,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointLabelChange(index: number, value: string) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      activeTableElements[index] = {
        ...activeTableElements[index],
        label: value,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointTypeChange(index: number, value: DatapointType) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      activeTableElements[index] = {
        ...activeTableElements[index],
        type: value,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointAddEnumChoice(datapointIndex: number) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      const enumChoices = activeTableElements[datapointIndex].choices || [];

      const newEmptyChoice = {
        localId: ulid(),
        value: '',
        label: '',
      };

      activeTableElements[datapointIndex] = {
        ...activeTableElements[datapointIndex],
        choices: [...enumChoices, newEmptyChoice],
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointRemoveEnumChoice(datapointIndex: number, choiceId: string) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      const enumChoices = activeTableElements[datapointIndex].choices.filter(
        (item: EnumChoice & { localId: string }) => item.localId !== choiceId
      );

      activeTableElements[datapointIndex] = {
        ...activeTableElements[datapointIndex],
        choices: enumChoices,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointEnumChoiceValueChange(
    datapointIndex: number,
    choiceId: string,
    value: string
  ) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      const updatedChoices = [...activeTableElements[datapointIndex].choices];
      const affectedChoiceIndex = updatedChoices.findIndex(
        (item) => item.localId === choiceId
      );

      if (affectedChoiceIndex > -1) {
        updatedChoices[affectedChoiceIndex].value = value;
      }

      activeTableElements[datapointIndex] = {
        ...activeTableElements[datapointIndex],
        choices: updatedChoices,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointEnumChoiceLabelChange(
    datapointIndex: number,
    choiceId: string,
    value: string
  ) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      const updatedChoices = [...activeTableElements[datapointIndex].choices];
      const affectedChoiceIndex = updatedChoices.findIndex(
        (item) => item.localId === choiceId
      );

      if (affectedChoiceIndex > -1) {
        updatedChoices[affectedChoiceIndex].label = value;
      }

      activeTableElements[datapointIndex] = {
        ...activeTableElements[datapointIndex],
        choices: updatedChoices,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointSourceChange(datapointIndex: number, value: any[]) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements;
      activeTableElements[datapointIndex] = {
        ...activeTableElements[datapointIndex],
        sources: value,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointHooksChange(datapointIndex: number, hookUris: string[]) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements;
      activeTableElements[datapointIndex] = {
        ...activeTableElements[datapointIndex],
        hooks: hookUris,
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function onNestedDatapointCreateSource(datapointIndex: number, value: string) {
    if (activeTable) {
      const activeTableElements = activeTable.elements?.elements || [];
      const currentSources: string[] =
        activeTableElements[datapointIndex].sources || [];

      activeTableElements[datapointIndex] = {
        ...activeTableElements[datapointIndex],
        sources: [...currentSources, value],
      };

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: activeTableElements,
        },
      });
    }
  }

  function handleRemoveNestedDatapoint(index: number) {
    if (activeTable?.elements?.elements) {
      const nestedDatapoints = activeTable.elements.elements;
      nestedDatapoints.splice(index, 1);
      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable?.elements,
          elements: nestedDatapoints,
        },
      });
    }
  }

  const datapointForm = (
    <EuiForm component="form">
      {visibleEditSchemaElementIdModal == 'datapoint' && activeDatapoint?.id && (
        <EditSchemaElementIdModal
          schemaId={props.schemaId}
          elementId={activeDatapoint.id}
          onClose={() => setVisibleEditSchemaElementIdModal(null)}
          onSuccess={closeFlyout}
        />
      )}
      <EuiFormRow
        label={intl.formatMessage({ id: 'schemaBuilder.id', defaultMessage: 'ID' })}
        labelAppend={
          isEditFlyout && (
            <EuiButtonIcon
              iconType="pencil"
              aria-label="Edit"
              title={intl.formatMessage({ id: 'button.edit', defaultMessage: 'Edit' })}
              onClick={() => setVisibleEditSchemaElementIdModal('datapoint')}
            />
          )
        }
      >
        <EuiFieldText
          name="datapointId"
          disabled={isEditFlyout}
          autoComplete="off"
          value={activeDatapoint?.id || ''}
          onChange={handleDatapointIdChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.label',
          defaultMessage: 'Label',
        })}
      >
        <EuiFieldText
          name="datapointLabel"
          autoComplete="off"
          value={activeDatapoint?.label || ''}
          onChange={handleDatapointLabelChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({ id: 'schemaBuilder.type', defaultMessage: 'Type' })}
      >
        <EuiSelect
          id="datapointLabel"
          options={[
            { value: DatapointType.String, text: 'String' },
            { value: DatapointType.Date, text: 'Date' },
            { value: DatapointType.Datetime, text: 'Datetime' },
            { value: DatapointType.Number, text: 'Number' },
            { value: DatapointType.Enum, text: 'Enum' },
          ]}
          value={activeDatapoint?.type}
          onChange={(e) => handleDatapointTypeChange(e)}
          aria-label="Select datapoint type"
        />
      </EuiFormRow>

      {activeDatapoint?.type === DatapointType.Enum && (
        <EuiFormRow>
          <EuiAccordion
            id="dataPointEnumTypeConfiguration"
            buttonContent={intl.formatMessage({
              id: 'schemaBuilder.configureChoices',
              defaultMessage: 'Configure choices',
            })}
            paddingSize="l"
          >
            {!!activeDatapointEnumChoices.length &&
              activeDatapointEnumChoices.map((choice, index) => {
                return (
                  <EuiFlexGroup gutterSize="s" key={`datapoint-enum-choice-${index}`}>
                    <EuiFlexItem grow={5}>
                      <EuiFieldText
                        placeholder={intl.formatMessage({
                          id: 'schemaBuilder.value',
                          defaultMessage: 'Value',
                        })}
                        value={choice.value}
                        onChange={(e) => onDatapointEnumValueChange(e, choice.localId)}
                        aria-label="Set value of datapoint enum item"
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={5}>
                      <EuiFieldText
                        placeholder={intl.formatMessage({
                          id: 'schemaBuilder.label',
                          defaultMessage: 'Label',
                        })}
                        value={choice.label}
                        onChange={(e) => onDatapointEnumLabelChange(e, choice.localId)}
                        aria-label="Set label of datapoint enum item"
                      />
                    </EuiFlexItem>
                    <Can I={PERMISSION.SCHEMAS_UPDATE}>
                      <EuiFlexItem
                        grow={1}
                        style={{ justifyContent: 'center', alignItems: 'flex-end' }}
                      >
                        <EuiButtonIcon
                          aria-label="Remove datapoint enum item"
                          iconType="trash"
                          title={intl.formatMessage({
                            id: 'button.delete',
                            defaultMessage: 'Delete',
                          })}
                          iconSize="m"
                          color="text"
                          onClick={() =>
                            handleRemoveDatapointEnumChoice(choice.localId)
                          }
                        />
                      </EuiFlexItem>
                    </Can>
                  </EuiFlexGroup>
                );
              })}

            <EuiFlexGroup>
              <EuiFlexItem grow={true}>
                <EuiButton
                  fullWidth
                  iconType="plusInCircleFilled"
                  size="s"
                  aria-label="Add choice"
                  onClick={addNewEnumChoice}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiAccordion>
        </EuiFormRow>
      )}

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.sources',
          defaultMessage: 'Sources',
        })}
      >
        <EuiComboBox
          placeholder={intl.formatMessage({
            id: 'schemaBuilder.selectSource',
            defaultMessage: 'Select source',
          })}
          options={[{ label: `trained/${activeDatapoint?.id}` }]}
          selectedOptions={
            activeDatapoint?.sources?.map((source) => ({ label: source })) || []
          }
          onChange={handleSourcesChange}
          onCreateOption={onCreateSource}
          isClearable={true}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.hooks',
          defaultMessage: 'Hooks',
        })}
      >
        <SchemaHooks
          key={activeDatapoint?.id || 'hooks'}
          schemaContent={data?.content || { elements: [] }}
          hooksSchemaElementId={activeDatapoint?.id || ''}
          hookUris={activeDatapoint?.hooks || []}
          onChange={handleHooksChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.redaction',
          defaultMessage: 'Redaction',
        })}
      >
        <EuiSwitch
          label={intl.formatMessage({
            id: 'schemaBuilder.redaction.enabled',
            defaultMessage: 'Enabled',
          })}
          checked={activeDatapoint?.redaction?.is_enabled || false}
          onChange={handleDatapointRedactionEnabledChange}
        />
      </EuiFormRow>
    </EuiForm>
  );

  const sectionForm = (
    <EuiForm component="form">
      {visibleEditSchemaElementIdModal == 'section' && activeSection?.id && (
        <EditSchemaElementIdModal
          schemaId={props.schemaId}
          elementId={activeSection.id}
          onClose={() => setVisibleEditSchemaElementIdModal(null)}
          onSuccess={closeFlyout}
        />
      )}
      <EuiFormRow
        label="ID"
        labelAppend={
          isEditFlyout && (
            <EuiButtonIcon
              iconType="pencil"
              aria-label="Edit"
              title={intl.formatMessage({
                id: 'schemaBuilder.edit',
                defaultMessage: 'Edit',
              })}
              onClick={() => setVisibleEditSchemaElementIdModal('section')}
            />
          )
        }
      >
        <EuiFieldText
          name="sectionId"
          disabled={isEditFlyout}
          autoComplete="off"
          value={activeSection?.id || ''}
          onChange={handleSectionIdChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.label',
          defaultMessage: 'Label',
        })}
      >
        <EuiFieldText
          name="sectionLabel"
          autoComplete="off"
          value={activeSection?.label || ''}
          onChange={handleSectionLabelChange}
        />
      </EuiFormRow>
    </EuiForm>
  );

  const listForm = (
    <EuiForm component="form">
      {visibleEditSchemaElementIdModal == 'list' && activeList?.id && (
        <EditSchemaElementIdModal
          schemaId={props.schemaId}
          elementId={activeList.id}
          onClose={() => setVisibleEditSchemaElementIdModal(null)}
          onSuccess={closeFlyout}
        />
      )}
      <EuiTitle size="s">
        <h3>List</h3>
      </EuiTitle>
      <EuiFormRow
        label={intl.formatMessage({ id: 'schemaBuilder.id', defaultMessage: 'ID' })}
        labelAppend={
          isEditFlyout && (
            <EuiButtonIcon
              iconType="pencil"
              aria-label="Edit"
              title={intl.formatMessage({ id: 'button.edit', defaultMessage: 'Edit' })}
              onClick={() => setVisibleEditSchemaElementIdModal('list')}
            />
          )
        }
      >
        <EuiFieldText
          name="listId"
          disabled={isEditFlyout}
          autoComplete="off"
          value={activeList?.id || ''}
          onChange={handleListIdChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.label',
          defaultMessage: 'Label',
        })}
      >
        <EuiFieldText
          name="listLabel"
          autoComplete="off"
          value={activeList?.label || ''}
          onChange={handleListLabelChange}
        />
      </EuiFormRow>

      <EuiHorizontalRule />

      <EuiTitle size="s">
        <h3>
          <Translate id="schemaBuilder.datapoints" defaultMessage="Datapoints" />
        </h3>
      </EuiTitle>
      {datapointForm}
    </EuiForm>
  );

  const tableForm = (
    <EuiForm component="form">
      {visibleEditSchemaElementIdModal == 'table' && activeTable?.id && (
        <EditSchemaElementIdModal
          schemaId={props.schemaId}
          elementId={activeTable.id}
          onClose={() => setVisibleEditSchemaElementIdModal(null)}
          onSuccess={closeFlyout}
        />
      )}
      <EuiTitle size="s">
        <h3>
          <Translate id="schemaBuilder.table" defaultMessage="Table" />
        </h3>
      </EuiTitle>
      <EuiFormRow
        label={intl.formatMessage({ id: 'schemaBuilder.id', defaultMessage: 'ID' })}
        labelAppend={
          isEditFlyout && (
            <EuiButtonIcon
              iconType="pencil"
              aria-label="Edit"
              title={intl.formatMessage({ id: 'button.edit', defaultMessage: 'Edit' })}
              onClick={() => setVisibleEditSchemaElementIdModal('table')}
            />
          )
        }
      >
        <EuiFieldText
          name="tableId"
          disabled={isEditFlyout}
          autoComplete="off"
          value={activeTable?.id || ''}
          onChange={handleTableIdChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.label',
          defaultMessage: 'Label',
        })}
      >
        <EuiFieldText
          name="tableLabel"
          autoComplete="off"
          value={activeTable?.label || ''}
          onChange={handleTableLabelChange}
        />
      </EuiFormRow>

      <EuiHorizontalRule />

      <EuiTitle size="s">
        <h3>
          <Translate id="schemaBuilder.rows" defaultMessage="Rows" />
        </h3>
      </EuiTitle>
      {visibleEditSchemaElementIdModal == 'table_tuple' &&
        activeTable?.elements?.id && (
          <EditSchemaElementIdModal
            schemaId={props.schemaId}
            elementId={activeTable.elements.id}
            onClose={() => setVisibleEditSchemaElementIdModal(null)}
            onSuccess={closeFlyout}
          />
        )}
      <EuiFormRow
        label={intl.formatMessage({ id: 'schemaBuilder.id', defaultMessage: 'ID' })}
        labelAppend={
          isEditFlyout && (
            <EuiButtonIcon
              iconType="pencil"
              aria-label="Edit"
              title={intl.formatMessage({ id: 'button.edit', defaultMessage: 'Edit' })}
              onClick={() => setVisibleEditSchemaElementIdModal('table_tuple')}
            />
          )
        }
      >
        <EuiFieldText
          name="rowId"
          disabled={isEditFlyout}
          autoComplete="off"
          value={activeTable?.elements?.id || ''}
          onChange={handleTableRowIdChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaBuilder.label',
          defaultMessage: 'Label',
        })}
      >
        <EuiFieldText
          name="rowLabel"
          autoComplete="off"
          value={activeTable?.elements?.label || ''}
          onChange={handleTableRowLabelChange}
        />
      </EuiFormRow>

      <EuiHorizontalRule />

      <EuiTitle size="s">
        <h3>
          <Translate id="schemaBuilder.columns" defaultMessage="Columns" />
        </h3>
      </EuiTitle>

      <EuiDragDropContext onDragEnd={onTableColumnsDragEnd}>
        <EuiDroppable
          droppableId="TABLE_COLUMNS_DROP"
          spacing="m"
          style={{ flex: '1 0 100%', marginBottom: 16 }}
        >
          <>
            {activeTable?.elements?.elements?.map(
              (datapoint: SchemaDatapoint | SchemaDatapointEnum, index: number) => {
                return (
                  <EuiDraggable
                    spacing="m"
                    key={`draggable-column-${index}`}
                    index={index}
                    draggableId={`draggable-column-${index}`}
                    customDragHandle={true}
                  >
                    {(provided) => (
                      <EuiPanel className="custom" paddingSize="m">
                        <EuiFlexGroup>
                          <EuiFlexItem grow={true}>
                            <EuiAccordion
                              id="dataPointEnumTypeConfiguration"
                              buttonContent={
                                datapoint.label ||
                                intl.formatMessage(
                                  {
                                    id: 'schemaBuilder.newColumn',
                                    defaultMessage: 'New column {columnNumber}',
                                  },
                                  { columnNumber: index + 1 }
                                )
                              }
                              paddingSize="s"
                              initialIsOpen={
                                flyoutProps.mode === FlyoutMode.CreateTable
                              }
                              extraAction={
                                <EuiFlexGroup>
                                  <Can I={PERMISSION.SCHEMAS_UPDATE}>
                                    <EuiFlexItem grow={false}>
                                      <EuiButtonIcon
                                        aria-label="Remove section"
                                        iconType="trash"
                                        title={intl.formatMessage({
                                          id: 'button.delete',
                                          defaultMessage: 'Delete',
                                        })}
                                        iconSize="m"
                                        color="text"
                                        onClick={() =>
                                          handleRemoveNestedDatapoint(index)
                                        }
                                        disabled={isDragAndDropDisabled}
                                      />
                                    </EuiFlexItem>
                                  </Can>

                                  <EuiFlexItem grow={false}>
                                    <div
                                      {...provided.dragHandleProps}
                                      aria-label="Drag handle"
                                    >
                                      <EuiIcon type="grab" />
                                    </div>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              }
                            >
                              <NestedDatapoint
                                index={index}
                                id={datapoint.id}
                                label={datapoint.label}
                                type={datapoint.type as DatapointType}
                                enumChoices={datapoint.choices || undefined}
                                sources={datapoint.sources || []}
                                hooks={datapoint.hooks || []}
                                schemaContent={data?.content || { elements: [] }}
                                onIdChange={onNestedDatapointIdChange}
                                onLabelChange={onNestedDatapointLabelChange}
                                onTypeChange={onNestedDatapointTypeChange}
                                onAddEnumChoice={onNestedDatapointAddEnumChoice}
                                onRemoveEnumChoice={onNestedDatapointRemoveEnumChoice}
                                onEnumChoiceLabelChange={
                                  onNestedDatapointEnumChoiceLabelChange
                                }
                                onEnumChoiceValueChange={
                                  onNestedDatapointEnumChoiceValueChange
                                }
                                onCreateSource={onNestedDatapointCreateSource}
                                onSourceChange={onNestedDatapointSourceChange}
                                onHooksChange={onNestedDatapointHooksChange}
                                schemaId={props.schemaId}
                                closeFlyout={closeFlyout}
                                isEditing={activeTableStoredDatapointIds.includes(
                                  datapoint.id
                                )}
                              />
                            </EuiAccordion>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                    )}
                  </EuiDraggable>
                );
              }
            )}
          </>
        </EuiDroppable>
      </EuiDragDropContext>

      <Can I={PERMISSION.SCHEMAS_UPDATE}>
        <EuiButton
          onClick={handleCreateActiveTableDatapoint}
          style={{ marginBottom: '16px' }}
        >
          <Translate id="schemaBuilder.addColumn" defaultMessage="Add column" />
        </EuiButton>
      </Can>
    </EuiForm>
  );

  useEffect(() => {
    const currentSchema = data as SchemaGetOut;
    if (currentSchema) {
      setSchemaData({
        sections: currentSchema.content.elements.filter(
          (element) => element.kind === SchemaElementKind.Section
        ),
        isTouched: false,
      });
      setContentHooks(currentSchema.content.hooks || null);
    }
  }, [data]);

  useEffect(() => {
    if (updateFinishCode) {
      if (errorCode === 405) {
        setIsOpenSaveAsNewModal(true);
      } else {
        setIsOpenSaveAsNewModal(false);
      }
    }
  }, [updateFinishCode]);

  useEffect(() => {
    if (schemaData.isTouched) {
      saveSchema();
    }
  }, [schemaData]);

  useEffect(() => {
    if (activeDatapoint && activeList) {
      setActiveList({
        ...activeList,
        elements: activeDatapoint,
      });
    }
  }, [activeDatapoint]);

  useEffect(() => {
    setIsDragAndDropDisabled(
      isSaving || (flyoutProps.mode === FlyoutMode.JsonEditor && flyoutProps.isVisible)
    );
  }, [isSaving, flyoutProps]);

  function saveSchema() {
    const schemaContent = {
      elements: [...schemaData.sections],
      hooks: contentHooks,
    };

    saveSchemaChanges((schemaContent as unknown) as SchemaContentType);
  }

  function reorderSections(sourceIndex: number, destinationIndex: number) {
    const updatedSections = [...schemaData.sections];
    const movedSection = schemaData.sections[sourceIndex];
    updatedSections.splice(sourceIndex, 1);
    updatedSections.splice(destinationIndex, 0, movedSection);
    setSchemaData({ sections: updatedSections, isTouched: true });
  }

  function reorderDataElements(
    sectionId: string,
    sourceIndex: number,
    destinationIndex: number
  ) {
    const sectionIndex = schemaData.sections.findIndex(
      (section: SchemaSection) => section.id === sectionId
    );
    const updatedSections = [...schemaData.sections];

    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      elements: [
        ...euiDragDropReorder(
          updatedSections[sectionIndex].elements,
          sourceIndex,
          destinationIndex
        ),
      ],
    };

    setSchemaData({ sections: updatedSections, isTouched: true });
  }

  function moveDataElementCrossSections(
    source: { index: number; droppableId: string },
    destination: { index: number; droppableId: string }
  ) {
    const updatedSections = [...schemaData.sections];
    const sourceSectionIndex = schemaData.sections.findIndex(
      (section: SchemaSection) => section.id === source.droppableId
    );
    const destinationSectionIndex = schemaData.sections.findIndex(
      (section: SchemaSection) => section.id === destination.droppableId
    );

    const sourceSectionDataElements =
      schemaData.sections[sourceSectionIndex].elements || [];
    const destinationSectionDataElements =
      schemaData.sections[destinationSectionIndex].elements || [];
    const movedDataElement = sourceSectionDataElements[source.index];

    destinationSectionDataElements.splice(destination.index, 0, movedDataElement);
    sourceSectionDataElements.splice(source.index, 1);

    const updatedDestinationSection = {
      ...schemaData.sections[destinationSectionIndex],
      elements: [...destinationSectionDataElements],
    };

    const updatedSourceSection = {
      ...schemaData.sections[sourceSectionIndex],
      elements: [...sourceSectionDataElements],
    };

    updatedSections[sourceSectionIndex] = updatedSourceSection;
    updatedSections[destinationSectionIndex] = updatedDestinationSection;
    setSchemaData({ sections: updatedSections, isTouched: true });
  }

  function onDragEnd(result: DropResult) {
    if (result.source && result.destination) {
      if (result.destination.droppableId === 'SCHEMA-SECTIONS') {
        reorderSections(result.source.index, result.destination.index);
      } else if (result.source.droppableId === result.destination.droppableId) {
        reorderDataElements(
          result.destination.droppableId,
          result.source.index,
          result.destination.index
        );
      } else {
        moveDataElementCrossSections(result.source, result.destination);
      }
    }
  }

  function onTableColumnsDragEnd(result: DropResult) {
    if (activeTable?.elements?.elements && result.destination) {
      const tableColumns = [
        ...euiDragDropReorder(
          activeTable.elements.elements,
          result.source.index,
          result.destination.index
        ),
      ];

      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: tableColumns,
        },
      });
    }
  }

  function processActiveDatapointChanges() {
    const updatedSections = [...schemaData.sections];
    const activeSectionIndex = schemaData.sections.findIndex(
      (section) => section.id === activeSectionId
    );
    if (activeSectionIndex > -1 && Number(activeDataElementIndex) > -1) {
      if (activeDatapoint?.type === DatapointType.Enum) {
        const newDatapoint = {
          ...activeDatapoint,
          choices: activeDatapointEnumChoices.map((choice) => ({
            ...choice,
            localId: undefined,
          })),
        };
        updatedSections[activeSectionIndex].elements[
          Number(activeDataElementIndex)
        ] = newDatapoint as SchemaDatapointEnum;
      } else {
        updatedSections[activeSectionIndex].elements[
          Number(activeDataElementIndex)
        ] = activeDatapoint as SchemaDatapoint;
      }
    }
    setSchemaData({ sections: updatedSections, isTouched: true });
    closeFlyout();
  }

  function processActiveListChanges() {
    const updatedSections = [...schemaData.sections];
    const activeSectionIndex = schemaData.sections.findIndex(
      (section) => section.id === activeSectionId
    );

    if (activeSectionIndex > -1 && Number(activeDataElementIndex) > -1) {
      let updatedList = {
        ...activeList,
      };

      if (activeDatapoint?.type === DatapointType.Enum) {
        updatedList = {
          ...activeList,
          elements: {
            ...activeDatapoint,
            choices: activeDatapointEnumChoices.map((choice) => ({
              ...choice,
              localId: undefined,
            })),
          } as SchemaDatapointEnum,
        };
      }

      updatedSections[activeSectionIndex].elements[
        Number(activeDataElementIndex)
      ] = updatedList as SchemaList;
    }

    setSchemaData({ sections: updatedSections, isTouched: true });
    setActiveList(undefined);
    setActiveDatapoint(undefined);
    setActiveDatapointEnumChoices([]);
    closeFlyout();
  }

  function processActiveTableChanges() {
    const updatedSections = [...schemaData.sections];
    const activeSectionIndex = schemaData.sections.findIndex(
      (section) => section.id === activeSectionId
    );
    if (activeSectionIndex > -1 && Number(activeDataElementIndex) > -1) {
      updatedSections[activeSectionIndex].elements[
        Number(activeDataElementIndex)
      ] = activeTable as SchemaTable;
    }
    setSchemaData({ sections: updatedSections, isTouched: true });
    setActiveTable(undefined);
    closeFlyout();
  }

  function processActiveSectionChanges() {
    const editedSections = [...schemaData.sections];
    if (typeof activeSectionIndex === 'number' && activeSectionIndex > -1) {
      editedSections[activeSectionIndex] = activeSection as SchemaSection;
    }
    setSchemaData({ sections: editedSections, isTouched: true });
    closeFlyout();
  }

  function handleElementEditClick(
    section: SchemaSection,
    dataElement: any,
    dataElementIndex: number
  ) {
    setActiveSection(undefined);
    if (flyoutProps.mode === FlyoutMode.JsonEditor && flyoutProps.isVisible) {
      findIdInJsonEditor(dataElement.id);
    } else {
      switch (dataElement.kind) {
        case SchemaElementKind.List:
          return handleEditList(
            dataElement as SchemaList,
            section.id,
            dataElementIndex
          );
        case SchemaElementKind.Table:
          return handleEditTable(
            dataElement as SchemaTable,
            section.id,
            dataElementIndex
          );
        case SchemaElementKind.Datapoint:
        default:
          return handleEditDatapoint(
            dataElement as SchemaDatapoint,
            section.id,
            dataElementIndex
          );
      }
    }
  }

  function handleRemoveDataElement(
    dataElement: SchemaDatapoint | SchemaDatapointEnum | SchemaList | SchemaTable,
    sectionId: string
  ) {
    setActiveSectionId(sectionId);
    setDataElementToDelete({ id: dataElement.id, label: dataElement.label });
    setIsDeletionConfirmModalVisible(true);
  }

  function onCancelDeleteDataElement() {
    setIsDeletionConfirmModalVisible(false);
    setActiveTable(undefined);
  }

  function onConfirmDeleteDataElement() {
    const updatedSections = [...schemaData.sections];
    const sectionIndex = schemaData.sections.findIndex(
      (section) => section.id === activeSectionId
    );
    updatedSections[sectionIndex].elements = schemaData.sections[
      sectionIndex
    ].elements.filter((element) => element.id !== dataElementToDelete?.id);
    setSchemaData({ sections: updatedSections, isTouched: true });
    setIsDeletionConfirmModalVisible(false);
    setDataElementToDelete(undefined);
  }

  function handleRemoveSection(section: SchemaSection) {
    setActiveSection(section);
    setIsDeleteSectionConfirmModalVisible(true);
  }

  function onCancelDeleteSection() {
    setIsDeleteSectionConfirmModalVisible(false);
    setActiveSection(undefined);
  }

  function onConfirmDeleteSection() {
    setSchemaData({
      sections: schemaData.sections.filter(
        (section) => section.id !== activeSection?.id
      ),
      isTouched: true,
    });
    setIsDeleteSectionConfirmModalVisible(false);
    setActiveSection(undefined);
  }

  function handleCreateSection() {
    setActiveSection({
      kind: SchemaElementKind.Section,
      id: '',
      label: '',
      elements: [],
    });
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.CreateSection,
      isVisible: true,
    });
  }

  function onCreateSection() {
    const updatedSections = [...schemaData.sections];
    updatedSections.push(activeSection as SchemaSection);
    setSchemaData({ sections: updatedSections, isTouched: true });
    setActiveSection(undefined);
    closeFlyout();
  }

  function handleCreateDatapoint(sectionIndex: number) {
    setActiveSectionIndex(sectionIndex);
    setActiveDatapoint({
      kind: SchemaElementKind.Datapoint,
      id: '',
      label: '',
      type: DatapointType.String,
      sources: [],
      constraints: undefined,
    });
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.CreateDatapoint,
      isVisible: true,
    });
  }

  function onCreateDatapoint() {
    const updatedSections = [...schemaData.sections];
    if (activeSectionIndex !== undefined) {
      if (activeDatapoint?.type === DatapointType.Enum) {
        const newDatapoint = {
          ...activeDatapoint,
          choices: activeDatapointEnumChoices.map((choice) => ({
            ...choice,
            localId: undefined,
          })),
        };
        updatedSections[activeSectionIndex].elements.push(
          newDatapoint as SchemaDatapointEnum
        );
      } else {
        updatedSections[activeSectionIndex].elements.push(
          activeDatapoint as SchemaDatapoint
        );
      }
    }
    setSchemaData({ sections: updatedSections, isTouched: true });
    setActiveSectionIndex(undefined);
    setActiveDatapoint(undefined);
    setActiveDatapointEnumChoices([]);
    closeFlyout();
  }

  function handleEditDatapoint(
    datapoint: SchemaDatapoint | SchemaDatapointEnum,
    sectionId: string,
    index: number
  ) {
    if (datapoint.type === DatapointType.Enum) {
      if ('choices' in datapoint) {
        setActiveDatapointEnumChoices(
          datapoint?.choices?.map((choice: EnumChoice) => ({
            ...choice,
            localId: ulid(),
          }))
        );
      }
    }
    setActiveDatapoint(datapoint);
    setActiveSectionId(sectionId);
    setActiveDataElementIndex(index);
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.EditDatapoint,
      isVisible: true,
    });
  }

  function handleEditList(
    list: SchemaList,
    sectionId: string,
    dataElementIndex: number
  ) {
    if (list.elements?.type === DatapointType.Enum) {
      if ('choices' in list.elements) {
        setActiveDatapointEnumChoices(
          list.elements?.choices?.map((choice: EnumChoice) => ({
            ...choice,
            localId: ulid(),
          }))
        );
      }
    } else {
      setActiveDatapointEnumChoices([]);
    }
    setActiveList(list);
    setActiveDatapoint(list.elements);
    setActiveSectionId(sectionId);
    setActiveDataElementIndex(dataElementIndex);
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.EditList,
      isVisible: true,
    });
  }

  function handleEditTable(
    table: SchemaTable,
    sectionId: string,
    dataElementIndex: number
  ) {
    setActiveTable(table);
    setActiveTableStoredDatapointIds(table.elements.elements.map((value) => value.id));
    setActiveSectionId(sectionId);
    setActiveDataElementIndex(dataElementIndex);
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.EditTable,
      isVisible: true,
    });
  }

  function handleEditSection(section: SchemaSection, index: number) {
    if (flyoutProps.mode === FlyoutMode.JsonEditor && flyoutProps.isVisible) {
      findIdInJsonEditor(section.id);
    } else {
      setActiveSection(section);
      setActiveDataElementIndex(undefined);
      setActiveSectionIndex(index);
      setFlyoutProps({
        ...flyoutProps,
        mode: FlyoutMode.EditSection,
        isVisible: true,
      });
    }
  }

  function handleCreateList(sectionIndex: number) {
    const newList = {
      kind: SchemaElementKind.List,
      id: '',
      label: '',
      sources: [],
      elements: {
        kind: SchemaElementKind.Datapoint,
        id: '',
        label: '',
        type: DatapointType.String,
        sources: [],
        constraints: undefined,
      },
    };
    setActiveSectionIndex(sectionIndex);
    setActiveList(newList);
    setActiveDatapoint({
      kind: SchemaElementKind.Datapoint,
      id: '',
      label: '',
      type: DatapointType.String,
      sources: [],
      constraints: undefined,
    });
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.CreateList,
      isVisible: true,
    });
  }

  function onCreateList() {
    const updatedSections = [...schemaData.sections];
    if (activeSectionIndex !== undefined) {
      if (activeDatapoint?.type === DatapointType.Enum) {
        const newDatapoint = {
          ...activeDatapoint,
          choices: activeDatapointEnumChoices.map((choice) => ({
            ...choice,
            localId: undefined,
          })),
        };

        const newList = {
          ...activeList,
          elements: newDatapoint as SchemaDatapointEnum,
        };

        updatedSections[activeSectionIndex].elements.push(newList as SchemaList);
      } else {
        updatedSections[activeSectionIndex].elements.push({
          ...activeList,
          elements: activeDatapoint as SchemaDatapoint,
        } as SchemaList);
      }
    }
    setSchemaData({ sections: updatedSections, isTouched: true });
    setActiveSectionIndex(undefined);
    setActiveDatapoint(undefined);
    setActiveDatapointEnumChoices([]);
    closeFlyout();
  }

  function handleCreateTable(sectionIndex: number) {
    const newTable = {
      kind: SchemaElementKind.Table,
      id: '',
      label: '',
      sources: [],
      elements: {
        kind: SchemaElementKind.Tuple,
        id: '',
        label: '',
        elements: [],
        sources: [],
      },
    };
    setActiveSectionIndex(sectionIndex);
    setActiveTable(newTable);
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.CreateTable,
      isVisible: true,
    });
  }

  function onCreateTable() {
    const updatedSections = [...schemaData.sections];
    if (activeSectionIndex !== undefined) {
      updatedSections[activeSectionIndex].elements.push(activeTable as SchemaTable);
    }

    setSchemaData({ sections: updatedSections, isTouched: true });
    setActiveSectionIndex(undefined);
    closeFlyout();
  }

  function handleCreateActiveTableDatapoint() {
    if (activeTable) {
      const currentElements = activeTable?.elements?.elements || [];
      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          elements: [
            ...currentElements,
            {
              id: '',
              label: '',
              type: DatapointType.String,
              sources: [],
              kind: SchemaElementKind.Datapoint,
            },
          ],
        },
      });
    }
  }

  function handleDatapointIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeDatapoint) {
      setActiveDatapoint({
        ...activeDatapoint,
        id: event.target.value,
      });
    }
  }

  function handleDatapointLabelChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeDatapoint) {
      setActiveDatapoint({
        ...activeDatapoint,
        label: event.target.value,
      });
    }

    if (activeList) {
      setActiveList({
        ...activeList,
        elements: activeDatapoint,
      });
    }
  }

  function handleSourcesChange(selectedOptions: Array<{ label: string }>) {
    if (activeDatapoint) {
      setActiveDatapoint({
        ...activeDatapoint,
        sources: selectedOptions.map((source) => source.label),
      });
    }
  }

  function handleDatapointTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setActiveDatapoint({
      ...activeDatapoint,
      // @ts-ignore
      type: event.target.value,
    });
  }

  function onCreateSource(inputString: string) {
    if (activeDatapoint) {
      let currentSources: string[] = [];
      if (activeDatapoint.sources) {
        currentSources = [...activeDatapoint.sources];
      }

      setActiveDatapoint({
        ...activeDatapoint,
        sources: [...currentSources, inputString],
      });
    }
  }

  function handleSectionIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeSection) {
      setActiveSection({
        ...activeSection,
        id: event.target.value,
      });
    }
  }

  function handleSectionLabelChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeSection) {
      setActiveSection({
        ...activeSection,
        label: event.target.value,
      });
    }
  }

  function handleListIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeList) {
      setActiveList({
        ...activeList,
        id: event.target.value,
      });
    }
  }

  function handleListLabelChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeList) {
      setActiveList({
        ...activeList,
        label: event.target.value,
      });
    }
  }

  function isListFormValid(): boolean {
    return (
      activeList?.label?.length &&
      activeList?.id?.length &&
      activeList?.elements?.id?.length &&
      activeList?.elements?.label?.length
    );
  }

  function handleTableIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeTable) {
      setActiveTable({
        ...activeTable,
        id: event.target.value,
      });
    }
  }

  function handleTableLabelChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeTable) {
      setActiveTable({
        ...activeTable,
        label: event.target.value,
      });
    }
  }

  function handleTableRowIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeTable) {
      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          id: event.target.value,
        },
      });
    }
  }

  function handleTableRowLabelChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (activeTable) {
      setActiveTable({
        ...activeTable,
        elements: {
          ...activeTable.elements,
          label: event.target.value,
        },
      });
    }
  }

  function isTableFormValid(): boolean {
    if (!activeTable?.label?.length || !activeTable?.id?.length) {
      return false;
    }

    if (!activeTable?.elements?.label?.length || !activeTable?.elements?.id?.length) {
      return false;
    }

    if (!activeTable?.elements?.elements.length) {
      return false;
    }

    for (const datapoint of activeTable?.elements?.elements) {
      if (!datapoint.id.length || !datapoint.label.length) {
        return false;
      }
    }

    return true;
  }

  function handleOpenEditor() {
    setFlyoutProps({
      ...flyoutProps,
      mode: FlyoutMode.JsonEditor,
      isVisible: true,
    });
  }

  const resetActiveState = () => {
    setActiveSection(undefined);
    setActiveDatapoint(undefined);
    setActiveList(undefined);
    setActiveTable(undefined);
    setActiveDataElementIndex(undefined);
  };

  function closeFlyout() {
    setFlyoutProps({
      ...flyoutProps,
      isVisible: false,
    });
    resetActiveState();
  }

  function getFlyoutTitle() {
    switch (flyoutProps.mode) {
      case FlyoutMode.CreateDatapoint:
        return intl.formatMessage({
          id: 'schemaBuilder.addNewDatapoint',
          defaultMessage: 'Add new datapoint',
        });
      case FlyoutMode.EditDatapoint:
        return intl.formatMessage({
          id: 'schemaBuilder.editDatapoint',
          defaultMessage: 'Edit datapoint',
        });
      case FlyoutMode.CreateSection:
        return intl.formatMessage({
          id: 'schemaBuilder.addNewSection',
          defaultMessage: 'Add new section',
        });
      case FlyoutMode.EditSection:
        return intl.formatMessage({
          id: 'schemaBuilder.editSection',
          defaultMessage: 'Edit section',
        });
      case FlyoutMode.CreateList:
        return intl.formatMessage({
          id: 'schemaBuilder.addListOfDatapoints',
          defaultMessage: 'Add list of datapoints',
        });
      case FlyoutMode.EditList:
        return intl.formatMessage({
          id: 'schemaBuilder.editListOfDatapoints',
          defaultMessage: 'Edit list of datapoints',
        });
      case FlyoutMode.CreateTable:
        return intl.formatMessage({
          id: 'schemaBuilder.addTable',
          defaultMessage: 'Add table',
        });
      case FlyoutMode.EditTable:
        return intl.formatMessage({
          id: 'schemaBuilder.editTable',
          defaultMessage: 'Edit table',
        });
      case FlyoutMode.JsonEditor:
        return intl.formatMessage({
          id: 'schemaBuilder.schemaJsonEditor',
          defaultMessage: 'Schema JSON editor',
        });
      default:
        return intl.formatMessage({
          id: 'schemaBuilder.datapoint',
          defaultMessage: 'Datapoint',
        });
    }
  }

  function getFlyoutActions() {
    switch (flyoutProps.mode) {
      case FlyoutMode.CreateDatapoint:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <EuiFlexItem grow={false}>
              <EuiButton
                iconType="plus"
                onClick={onCreateDatapoint}
                disabled={
                  !activeDatapoint?.label?.length || !activeDatapoint?.id?.length
                }
                fill
              >
                <Translate id="button.add" defaultMessage="Add" />
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.EditDatapoint:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <Can I={PERMISSION.SCHEMAS_UPDATE}>
              <EuiFlexItem grow={false}>
                <EuiButton iconType="save" onClick={processActiveDatapointChanges} fill>
                  <Translate id="button.save" defaultMessage="Save" />
                </EuiButton>
              </EuiFlexItem>
            </Can>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.CreateSection:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <EuiFlexItem grow={false}>
              <EuiButton
                iconType="plus"
                onClick={onCreateSection}
                disabled={!activeSection?.label?.length || !activeSection?.id?.length}
                fill
              >
                <Translate id="button.add" defaultMessage="Add" />
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.EditSection:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <Can I={PERMISSION.SCHEMAS_UPDATE}>
              <EuiFlexItem grow={false}>
                <EuiButton iconType="save" onClick={processActiveSectionChanges} fill>
                  <Translate id="button.save" defaultMessage="Save" />
                </EuiButton>
              </EuiFlexItem>
            </Can>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.EditList:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <Can I={PERMISSION.SCHEMAS_UPDATE}>
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconType="save"
                  onClick={processActiveListChanges}
                  disabled={!isListFormValid()}
                  fill
                >
                  <Translate id="button.save" defaultMessage="Save" />
                </EuiButton>
              </EuiFlexItem>
            </Can>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.CreateList:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={onCreateList}
                iconType="plus"
                disabled={!isListFormValid()}
                fill
              >
                <Translate id="button.add" defaultMessage="Add" />
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.EditTable:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <Can I={PERMISSION.SCHEMAS_UPDATE}>
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconType="save"
                  onClick={processActiveTableChanges}
                  disabled={!isTableFormValid()}
                  fill
                >
                  <Translate id="button.save" defaultMessage="Save" />
                </EuiButton>
              </EuiFlexItem>
            </Can>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.CreateTable:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <EuiFlexItem grow={false}>
              <EuiButton
                iconType="plus"
                onClick={onCreateTable}
                disabled={!isTableFormValid()}
                fill
              >
                <Translate id="button.add" defaultMessage="Add" />
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      case FlyoutMode.JsonEditor:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <Can I={PERMISSION.SCHEMAS_UPDATE}>
              <EuiFlexItem grow={false}>
                <SchemaUpdate schemaId={props.schemaId} closeFlyout={closeFlyout} />
              </EuiFlexItem>
            </Can>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.cancel" defaultMessage="Cancel" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      default:
        return (
          <EuiFlexGroup justifyContent="flexStart">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">
                <Translate id="button.close" defaultMessage="Close" />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
    }
  }

  function getFlyoutContent() {
    switch (flyoutProps.mode) {
      case FlyoutMode.CreateDatapoint:
      case FlyoutMode.EditDatapoint:
        return datapointForm;
      case FlyoutMode.CreateSection:
      case FlyoutMode.EditSection:
        return sectionForm;
      case FlyoutMode.CreateList:
      case FlyoutMode.EditList:
        return listForm;
      case FlyoutMode.CreateTable:
      case FlyoutMode.EditTable:
        return tableForm;
      case FlyoutMode.JsonEditor:
        return (
          <SchemaContentEditor schemaId={props.schemaId} aceEditorRef={jsonEditorRef} />
        );
      default:
        return (
          <p>
            <Translate
              id="schemaBuilder.unknownAction"
              defaultMessage="Unknown action"
            />
          </p>
        );
    }
  }

  function findIdInJsonEditor(id: string) {
    // @ts-ignore
    jsonEditorRef?.current?.editor?.focus();
    // @ts-ignore
    jsonEditorRef?.current?.editor?.find(`"id": "${id}"`, {
      backwards: false,
      wrap: true,
      caseSensitive: true,
      wholeWord: false,
      regExp: false,
    });
  }

  if (flyoutProps.isVisible) {
    flyout = (
      <EuiFlyout
        ownFocus={flyoutProps.ownFocus}
        onClose={closeFlyout}
        aria-labelledby="flyoutTitle"
        type={flyoutProps.type}
        size={flyoutProps.mode === FlyoutMode.JsonEditor ? 'm' : 's'}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutTitle">{getFlyoutTitle()}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody className="schema-builder-flyout">
          {getFlyoutContent()}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>{getFlyoutActions()}</EuiFlyoutFooter>
      </EuiFlyout>
    );
  }

  if (isDeletionConfirmModalVisible) {
    deleteDataElementConfirmModal = (
      <EuiConfirmModal
        title={intl.formatMessage({
          id: 'schemaBuilder.areYouSure',
          defaultMessage: 'Are you sure?',
        })}
        onCancel={onCancelDeleteDataElement}
        onConfirm={onConfirmDeleteDataElement}
        cancelButtonText={intl.formatMessage({
          id: 'schemaBuilder.noKeepIt',
          defaultMessage: 'No, keep it',
        })}
        confirmButtonText={intl.formatMessage({
          id: 'schemaBuilder.yesDeleteIt',
          defaultMessage: 'Yes, delete it',
        })}
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>
          <Translate
            id="schemaBuilder.confirmationTextElement"
            defaultMessage={`
          Do you want to delete element <b>{element}</b> from the
          schema, including all annotations for this element?
          `}
            values={{
              b: (element: string) => <strong>{element}</strong>,
              element: dataElementToDelete?.label,
            }}
          />
        </p>
      </EuiConfirmModal>
    );
  }

  if (isDeleteSectionConfirmModalVisible) {
    deleteSectionConfirmModal = (
      <EuiConfirmModal
        title={intl.formatMessage({
          id: 'schemaBuilder.areYouSure',
          defaultMessage: 'Are you sure?',
        })}
        onCancel={onCancelDeleteSection}
        onConfirm={onConfirmDeleteSection}
        cancelButtonText={intl.formatMessage({
          id: 'schemaBuilder.noKeepIt',
          defaultMessage: 'No, keep it',
        })}
        confirmButtonText={intl.formatMessage({
          id: 'schemaBuilder.yesDeleteIt',
          defaultMessage: 'Yes, delete it',
        })}
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>
          <Translate
            id="schemaBuilder.confirmationTextSchema"
            defaultMessage={`
           Do you want to delete section <b>{element}</b> from the schema?
          `}
            values={{
              b: (element: string) => <strong>{element}</strong>,
              element: activeSection?.label,
            }}
          />
        </p>
      </EuiConfirmModal>
    );
  }

  return (
    <>
      <EuiDragDropContext onDragEnd={(result: DropResult) => onDragEnd(result)}>
        <EuiDroppable
          droppableId="SCHEMA-SECTIONS"
          type="MACRO"
          direction="vertical"
          spacing="none"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {schemaData.sections &&
            schemaData.sections.map((section, sectionIndex) => (
              <EuiDraggable
                key={`section-${section.id}`}
                index={sectionIndex}
                draggableId={`draggable-section-${section.id}`}
                spacing="l"
                style={{ padding: '0px 0px 16px 0px' }}
                disableInteractiveElementBlocking // Allows button to be drag handle
                isDragDisabled={isDragAndDropDisabled}
              >
                {(provided) => (
                  <EuiPanel color="subdued" paddingSize="s">
                    <div className="schema-section-header">
                      <div>
                        <EuiButtonIcon
                          iconType="grab"
                          aria-label="Drag Handle"
                          {...provided.dragHandleProps}
                        />
                        <span>{section.label}</span>
                      </div>
                      <div>
                        <Can I={PERMISSION.SCHEMAS_UPDATE}>
                          <EuiButtonIcon
                            aria-label="Remove section"
                            iconType="trash"
                            title={
                              section.elements.length == 0
                                ? intl.formatMessage({
                                    id: 'button.delete',
                                    defaultMessage: 'Delete',
                                  })
                                : intl.formatMessage({
                                    id: 'schemaBuilder.onlyEmptySection',
                                    defaultMessage:
                                      'Only empty sections can be deleted',
                                  })
                            }
                            iconSize="m"
                            color="text"
                            onClick={() => handleRemoveSection(section)}
                            disabled={
                              isDragAndDropDisabled || section.elements.length != 0
                            }
                          />
                        </Can>
                        <EuiButtonIcon
                          aria-label="Section options"
                          iconType="gear"
                          title={intl.formatMessage({
                            id: 'schemaBuilder.options',
                            defaultMessage: 'Options',
                          })}
                          iconSize="m"
                          color={activeSection?.id === section.id ? 'accent' : 'text'}
                          onClick={() => handleEditSection(section, sectionIndex)}
                        />
                      </div>
                    </div>

                    <EuiDroppable
                      droppableId={section.id}
                      type="MICRO"
                      spacing="m"
                      style={{ flex: '1 0 100%' }}
                    >
                      {section?.elements?.map((dataElement) => {
                        const dataElementIndex = section?.elements?.findIndex(
                          (element) => element.id === dataElement.id
                        );
                        return (
                          <EuiDraggable
                            key={`data-element-${dataElement.id}`}
                            index={dataElementIndex}
                            draggableId={`draggable-data-element-${dataElement.id}`}
                            spacing="m"
                            isDragDisabled={isDragAndDropDisabled}
                          >
                            <EuiSplitPanel.Outer direction="row" responsive={false}>
                              <EuiSplitPanel.Inner>
                                <EuiText>
                                  {dataElement.label}{' '}
                                  {dataElement.kind === SchemaElementKind.List &&
                                    ' (list)'}
                                  {dataElement.kind === SchemaElementKind.Table &&
                                    ' (table)'}
                                </EuiText>
                              </EuiSplitPanel.Inner>
                              <EuiSplitPanel.Inner style={{ textAlign: 'right' }}>
                                <Can I={PERMISSION.SCHEMAS_UPDATE}>
                                  <EuiButtonIcon
                                    aria-label="Remove item"
                                    iconType="trash"
                                    title={intl.formatMessage({
                                      id: 'button.delete',
                                      defaultMessage: 'Delete',
                                    })}
                                    iconSize="m"
                                    color="text"
                                    onClick={() =>
                                      handleRemoveDataElement(dataElement, section.id)
                                    }
                                    disabled={isDragAndDropDisabled}
                                  />
                                </Can>
                                <EuiButtonIcon
                                  aria-label="Item options"
                                  iconType="gear"
                                  title={intl.formatMessage({
                                    id: 'button.options',
                                    defaultMessage: 'Options',
                                  })}
                                  iconSize="m"
                                  color={
                                    activeDataElementIndex === dataElementIndex
                                      ? 'accent'
                                      : 'text'
                                  }
                                  onClick={() =>
                                    handleElementEditClick(
                                      section,
                                      dataElement,
                                      dataElementIndex
                                    )
                                  }
                                />
                              </EuiSplitPanel.Inner>
                            </EuiSplitPanel.Outer>
                          </EuiDraggable>
                        );
                      })}
                    </EuiDroppable>

                    <Can I={PERMISSION.SCHEMAS_UPDATE}>
                      <div className="schema-section-footer">
                        <EuiFlexGroup wrap>
                          <EuiFlexItem>
                            <EuiButton
                              onClick={() => handleCreateDatapoint(sectionIndex)}
                              isLoading={isSaving}
                              disabled={isDragAndDropDisabled}
                            >
                              <Translate
                                id="schemaBuilder.button.addDatapoint"
                                defaultMessage="Add datapoint"
                              />
                            </EuiButton>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiButton
                              onClick={() => handleCreateList(sectionIndex)}
                              isLoading={isSaving}
                              disabled={isDragAndDropDisabled}
                            >
                              <Translate
                                id="schemaBuilder.button.addList"
                                defaultMessage="Add list"
                              />
                            </EuiButton>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiButton
                              onClick={() => handleCreateTable(sectionIndex)}
                              isLoading={isSaving}
                              disabled={isDragAndDropDisabled}
                            >
                              <Translate
                                id="schemaBuilder.button.addTable"
                                defaultMessage="Add table"
                              />
                            </EuiButton>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </div>
                    </Can>
                  </EuiPanel>
                )}
              </EuiDraggable>
            ))}
        </EuiDroppable>
      </EuiDragDropContext>

      <Can I={PERMISSION.SCHEMAS_UPDATE}>
        <EuiButton
          fullWidth
          onClick={handleCreateSection}
          isLoading={isSaving}
          disabled={isDragAndDropDisabled}
        >
          <Translate
            id="schemaBuilder.button.addSection"
            defaultMessage="Add section"
          />
        </EuiButton>
      </Can>
      <SchemaSaveAsNewModalWindow
        open={isOpenSaveAsNewModal}
        originalSchema={data as SchemaGetOut}
        newContent={{
          elements: [...schemaData.sections],
        }}
        onClose={() => setIsOpenSaveAsNewModal(false)}
      />
      <EuiButton
        color="primary"
        onClick={handleOpenEditor}
        style={{
          position: 'fixed',
          right: 24,
          bottom: `calc(${BOTTOM_BAR_HEIGHT} + 16px)`,
          zIndex: 900,
        }}
      >
        <Translate id="schemaBuilder.button.schemaJson" defaultMessage="Schema JSON" />
      </EuiButton>
      {flyout}
      {deleteSectionConfirmModal}
      {deleteDataElementConfirmModal}
    </>
  );
}

export function EditSchemaElementIdModal(props: {
  schemaId: string;
  elementId: string;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { patchSchemaElementMutation } = useSchemas();
  const intl = useTranslate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched', defaultValues: { elementId: props.elementId } });

  const handleCloseModal = () => {
    props.onClose();
  };

  const handleSubmitForm = (data, e) => {
    e.preventDefault();
    const patch = {
      id: data.elementId,
    };
    const payload = {
      schemaId: props.schemaId,
      elementId: props.elementId,
      patch: patch,
    };
    patchSchemaElementMutation.mutate(payload, {
      onSuccess: props.onSuccess,
      onSettled: handleCloseModal,
    });
  };

  const { ref: elementIdRef, ...elementIdRest } = register('elementId', {
    required: true,
    maxLength: {
      value: 64,
      message: intl.formatMessage({ id: 'button.options', defaultMessage: 'Options' }),
      // @ts-ignore
      pattern: {
        value: /^[a-zA-Z0-9_\.\-/:]+$/,
        message: intl.formatMessage({
          id: 'validation.allowedLetters',
          defaultMessage: 'Allowed characters: letters, digits, _, ., -, /, :',
        }),
      },
    },
  });

  const form = (
    <EuiForm id="modalForm" component="form" onSubmit={handleSubmit(handleSubmitForm)}>
      <EuiFormRow
        label={intl.formatMessage({ id: 'schemaBuilder.id', defaultMessage: 'ID' })}
        error={[errors?.elementId?.message]}
        isInvalid={!!errors?.elementId}
      >
        <EuiFieldText
          {...elementIdRest}
          autoFocus
          fullWidth
          name="elementId"
          autoComplete="off"
          inputRef={elementIdRef}
        />
      </EuiFormRow>
    </EuiForm>
  );
  const modal = (
    <EuiModal onClose={handleCloseModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>
            <Translate id="schemaBuilder.editId" defaultMessage="Edit ID" />
          </h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>{form}</EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={handleCloseModal}>Cancel</EuiButtonEmpty>
        <EuiButton
          type="submit"
          form="modalForm"
          isDisabled={!_.isEmpty(errors)}
          isLoading={patchSchemaElementMutation.isLoading}
          fill
        >
          <Translate id="button.update" defaultMessage="Update" />
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );

  return modal;
}
