export const getAnnotationTemplateEndpoint = (annotationId) =>
  `api/v1/annotations/${annotationId}/mark_as_template`;

export const getAnnotationSplitEndpoint = (annotationId) =>
  `api/v1/annotations/${annotationId}/split`;

export const getAnnotationExportEndpoint = (annotationId) =>
  `api/v1/annotations/${annotationId}/export`;

export const getAnnotationItemEndpoint = (annotationId, annotationItemId) =>
  `api/v1/annotations/${annotationId}/items/${annotationItemId}`;

export const getAnnotationItemCommandsEndpoint = (annotationId) =>
  `api/v1/annotations/${annotationId}/items/commands`;

export const getAnnotationItemsEndpoint = (annotationId) =>
  `api/v1/annotations/${annotationId}/items`;

export const getAnnotationsByDocumentEndpoint = (
  documentId,
  include,
  showTestAnnotations
) => {
  let endpoint = `api/v1/documents/${documentId}/annotations`;
  include && include.forEach((item) => (endpoint = endpoint + `&include=${item}`));
  showTestAnnotations && (endpoint = endpoint + `&show_test_annotations=true`);
  endpoint = endpoint.replace('&', '?');
  return endpoint;
};

export const getDocumentEndpoint = (documentId) => `api/v1/documents/${documentId}`;

export const getSchemasEndpoint = () => `api/v1/schemas`;

export const getSchemaEndpoint = (schemaId) => `api/v1/schemas/${schemaId}`;

export const getReferenceDataListEndpoint = (folderIdList) => {
  let endpointUrl = `api/v1/reference_data`;
  if (folderIdList) {
    const params = new URLSearchParams();
    folderIdList.forEach((folderId) => params.append('folder_id', folderId));
    endpointUrl = `${endpointUrl}?${params.toString()}`;
  }
  return endpointUrl;
};

export const getReferenceDataEndpoint = (referenceDataId) => {
  if (referenceDataId) {
    return `api/v1/reference_data/${referenceDataId}`;
  }
  return 'api/v1/reference_data';
};

export const getReferenceDataContentEndpoint = (referenceDataId) =>
  `api/v1/reference_data/${referenceDataId}/content`;

export const getSchemaElementEndpoint = (schemaId, elementId) =>
  `api/v1/schemas/${schemaId}/elements/${elementId}`;

export const getTrainSchemaElementSourcesEndpoint = (schemaId) =>
  `api/v1/schemas/${schemaId}/train_sources`;

export const getSearchInDocumentEndpoint = (annotationId, searchPhrase) =>
  `api/v1/annotations/${annotationId}/search?phrase=${searchPhrase}`;

export const getDocumentTrashEndpoint = (documentId) =>
  `api/v1/documents/${documentId}/trash`;

export const getDocumentRedactionEndpoint = (documentId) =>
  `api/v1/documents/${documentId}/redact`;

export const getGlobalSettingsEndpoint = () => `api/v1/system/settings`;

export const getUsersEndpoint = () => `api/v1/users`;
export const getCurrentUserEndpoint = () => `api/v1/user`;
export const getCurrentUserPermissionsEndpoint = () => `api/v1/user/permissions`;
export const getCurrentUserSettingsEndpoint = () => `api/v1/user/settings`;

export const getIdpAuthEndpoint = () => `api/v1/auth`;

export const getGridContentAnnotationItemEndpoint = (
  annotationId,
  annotationItemId,
  gridId
) =>
  `api/v1/annotations/${annotationId}/items/${annotationItemId}/table_gridset/${gridId}/content`;

export const getGridAnnotationItemEndpoint = (annotationId, annotationItemId, gridId) =>
  `api/v1/annotations/${annotationId}/items/${annotationItemId}/table_gridset/${gridId}/grid`;

export const getFoldersEndpoint = () => 'api/v1/folders';
export const getFolderEndpoint = (folderId) => `api/v1/folders/${folderId}`;

export const getPermissionsEndpoint = (folderId) => {
  let url = 'api/v1/permissions';
  if (!!folderId) {
    url = `${url}?folder_id=${folderId}`;
  }
  return url;
};
export const getPermissionEndpoint = (permissionId) =>
  `api/v1/permissions/${permissionId}`;

export const getEmailMetadata = (emailId) => `api/v1/emails/${emailId}`;
export const getEmailContent = (emailId) => `api/v1/emails/${emailId}/content`;
export const getSystemLogs = () => `api/v1/system/logs/frontend`;
